import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import OrientationEvaluation from './orientation/OrientationEvaluation';
import HandWritingEvaluation from './hand-writing/HandWritingEvaluation';
import ConcentrationEvaluation from './concentration/ConcentrationEvaluation';
import FollowingEvaluation from './following/FollowingEvaluation';
import NameImagesEvaluation from './name-images/NameImagesEvaluation';
import { fetchPatients, selectPatientById } from '../../redux/patientsSlice';
import './QuestionnaireEvaluation.scoped.scss';
import './QuestionnaireEvaluation.scss';
import ApiService from '../../services/apiService';
import { calculateQuestionnaireScore, patchData } from '../../helpers/calculateQuestionnaireScore';
import TitleService from '../../services/title/titleService';
import helpers from '../../helpers/staticHelperMethods';

function QuestionnaireEvaluation(props) {
  const { t } = props;
  const navigate = useNavigate();
  const { patientId: patientIdString, questionnaireDate } = useParams();
  const dispatch = useDispatch();

  const patientId = parseInt(patientIdString, 10);
  const currentPatient = {
    ...useSelector((state) => selectPatientById(state, patientId)),
  };

  const incomingEvaluation = currentPatient.questionnaires.find(
    (q) => q.date === questionnaireDate,
  );
  const incomingOverwrite = currentPatient.overwrites.find(
    (q) => q.date === questionnaireDate,
  );

  const formattedDate = helpers.getDateString(
    new Date(questionnaireDate),
    t,
    false,
    true,
  );


  const headerLine = `${currentPatient.study_number} - ${t('questionnaire.ice_score')} - ${formattedDate}`;
  const currentPatientScore = calculateQuestionnaireScore(currentPatient, questionnaireDate);
  const evalDoneHint = currentPatientScore === null ?
    t('questionnaire.eval_to_do') : t('questionnaire.eval_done');
  const pageTitle = `${formattedDate}: ${evalDoneHint}`;


  useEffect(() => {
    if (currentPatient) {
      // change title
      // Add translations to distingush between Patient Details / Overview / Questionnare in Title
      TitleService.setTitle(headerLine);
    }
    // trigger event for header generation
    const patientDetailPageEvent = new CustomEvent('patientDetailPageEvent', {
      detail: currentPatient,
    });
    document.dispatchEvent(patientDetailPageEvent);
  }, [currentPatient]);

  // todo: useEffect to ensure that patient is loaded
  const [overwrite, setOverwrite] = useState(
    incomingOverwrite ?? {
      nameImages: [null, null, null],
      following: null,
      concentration: null,
      handwriting: null,
      orientation: {
        year: null,
        month: null,
        city: null,
        hospital: null,
      },
    },
  );
  const patchedData = patchData(incomingEvaluation, overwrite);

  async function saveOverwrites() {
    const body = {
      questionnaireDate,
      overwrite,
    };
    const endpoint = process.env.REACT_APP_ICE_OVERWRITES_ENDPOINT.replace(
      '%',
      currentPatient.id,
    );
    await ApiService.postData(endpoint, body);
    dispatch(fetchPatients());
    navigate('..');
  }

  return (
    <div className="page-container">
      <div className="card col-12 corner-radius">
        <div className="card-body title-container">
          <div className="title">
            {pageTitle}
            &nbsp;
            {currentPatientScore === null && (<FontAwesomeIcon
              icon={faCircleExclamation}
              className="attention-needed"
              size="1x"
            />)}
          </div>
        </div>
      </div>
      <OrientationEvaluation
        data={patchedData.orientation}
        setOverwrite={(values) =>
          setOverwrite((old) => ({
            ...old,
            orientation: {
              ...old.orientation,
              ...values,
            },
          }))
        }
      />
      <HandWritingEvaluation
        data={patchedData.handwriting}
        setOverwrite={(value) =>
          setOverwrite((old) => ({
            ...old,
            handwriting: value,
          }))
        }
      />
      <ConcentrationEvaluation
        data={patchedData.concentration}
        setOverwrite={(value) =>
          setOverwrite((old) => ({
            ...old,
            concentration: value,
          }))
        }
      />
      <FollowingEvaluation
        data={patchedData.following}
        setOverwrite={(value) =>
          setOverwrite((old) => ({
            ...old,
            following: value,
          }))
        }
      />
      <NameImagesEvaluation
        data={patchedData.nameImages}
        setOverwrite={(value, index) =>
          setOverwrite((old) => ({
            ...old,
            nameImages: old.nameImages.map((oldValue, i) =>
              (i === index ? value : oldValue)),
          }))
        }
      />
      <div className="button-container">
        <button onClick={() => navigate('..')}>
          {t('questionnaire.abort')}
        </button>
        <button onClick={saveOverwrites}>{t('questionnaire.save')}</button>
      </div>
    </div>
  );
}

QuestionnaireEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(QuestionnaireEvaluation);
