import errors from '../../errors';
import commonDe from '../../assets/locales/de/translations.json';

/**
 * This service is used to filter patients by filter text
 * @class
 */
class PatientFilterService {

  /**
   * @constructor
   * @property filterRegEx to handle invalid input
   * @property nameRegEx to handle valid letters
   * @property propertyValueDelimiter split symbol
   * @property propertyDelimiter split symbol
   * @property nameDelimiter split symbol
   */
  constructor() {
    // regular expression to check whether the passed data is a property search mechanism or not
    // The min length of the part before the ':' sign is 2. The max length to the right side of
    // the ':' sign is 4 and the min length 1.
    // Example: ok -> id:2     not ok -> id: ... and so on
    this.filterRegEx = new RegExp('([a-z]{2,}([_][a-z]{2,})?[:][0-9]{1,4})');
    this.nameRegEx = new RegExp('[a-zäöüßA-ZÄÖÜ0-9]');
    this.propertyValueDelmiter = ':';
    this.propertyDelimiter = ',';
    this.nameDelimiter = ' ';
  }

  /**
   * Patients array validation
   * @param {Array} patients
   */
  checkPatientsAreValid(patients) {
    if (!Array.isArray(patients)) throw new Error(errors.propertyIsNotAnArrayErr);
  }

  /**
   * Find patients by name
   * @param {Array} patients
   * @param {string} value
   * @returns {Array} patient array or null
   */
  findPatientsByName(patients, value) {
    this.checkPatientsAreValid(patients);
    const names = value.split(this.nameDelimiter);
    const acceptedNames = names.filter((name) => {
      if (!this.nameRegEx.test(name)) return null;
      return name;
    });
    if (!acceptedNames.length) return [];

    return patients.filter((patient) => {
      const lowerName = patient.name === undefined ? '' : patient.name.toLowerCase();
      const lowerSurname = patient.surname === undefined ? '' : patient.surname.toLowerCase();
      const lowerFilterName = acceptedNames[0].toLowerCase();
      if (acceptedNames.length > 1) {
        if ((lowerName.startsWith(lowerFilterName) &&
                lowerSurname.startsWith(acceptedNames[1].toLowerCase())) ||
            ((lowerName.startsWith(acceptedNames[1].toLowerCase()) &&
                lowerSurname.startsWith(lowerFilterName)))) {

          return patient;
        }
        // eslint-disable-next-line max-len
      } else if (lowerName.startsWith(lowerFilterName) || lowerSurname.startsWith(lowerFilterName)) {
        return patient;
      }
      return null;
    });
  }

  /**
   * This method runs through all patients and tries to find a patient characteristic
   * that matches the given parameter
   * @param {object} patients
   * @param {Array} properties
   * @returns {Array} patient array
   */
  findPatientsByProperties(patients, properties) {
    if (!Array.isArray(properties)) throw new Error('Properties parameter must be an array');
    this.checkPatientsAreValid(patients);
    // Variable to save all matches
    let allFilteredPatients = [];
    // iterate through the given properties
    properties.forEach((property) => {
      const splittedProperty = property.split(this.propertyValueDelmiter);
      const name = splittedProperty[0];
      const valueInt = parseInt(splittedProperty[1], 10);
      const valueFloat = parseFloat(splittedProperty[1]);
      // find a matching patient characteristic
      const filteredPatients = patients.filter((patient) => {
        if (allFilteredPatients.indexOf(patient) !== -1) return null;
        // search in vital parameters
        let vitalValue;

        switch (name) {
        case 'id':
          if (patient[name] === valueInt) { return patient; }
          break;
        case 'temperatur' || 'Temperatur':
          vitalValue = patient.temperature.find(param => param.value === valueFloat);
          break;
        case 'gewicht' || 'Gewicht':
          vitalValue = patient.weight.find(param => param.value === valueFloat);
          break;
        case 'systolisch' || 'Systolisch':
          vitalValue = patient.blood_pressure.find(param => param.value === valueInt);
          break;
        case 'diastolisch' || 'Diastolisch':
          vitalValue = patient.blood_pressure.find(param => param.name === valueInt);
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const translatedName = this.translateName(name);
          // eslint-disable-next-line no-case-declarations
          const patientSymptom = patient.symptoms.find(param => param.name === translatedName);
          if (patientSymptom && patientSymptom.values.length) {
            if (patientSymptom.values[0].value === valueInt)
              return patient;
          }
          break;
        }
        if (vitalValue) return patient;
        return null;
      });

      // concat the new data with the old array
      allFilteredPatients = allFilteredPatients.concat(filteredPatients);
    });

    return allFilteredPatients;
  }

  translateName(name) {
    const keyIdx = Object.values(commonDe.symptoms).findIndex(x => x === name);
    if (keyIdx) { return Object.keys(commonDe.symptoms)[keyIdx]; }
    return name;
  }

  /**
   * Filter the patients by text and return all matching patients
   * Combines all possible filter methods of this class
   * @param {object} patients
   * @param {string} filterText
   * @returns {Patient[]} filteredPatients or null
   */
  filterPatients(patients, filterText) {
    this.checkPatientsAreValid(patients);
    if (filterText.length === 0) return null;
    const splitProperties = filterText.split(this.propertyDelimiter);

    const allowedProperties = [];

    // contains the splitted properties a property search mechanism?
    // filter can be concatenated with a comma (',')
    splitProperties.forEach((element) => {
      const isAllowed = this.filterRegEx.test(element);
      if (!isAllowed) return;
      allowedProperties.push(this.filterRegEx.exec(element).input);
    });

    let filteredPatients = [];
    filteredPatients = this.findPatientsByName(patients, filterText);
    return filteredPatients;
  }
}

export default new PatientFilterService();
