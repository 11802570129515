import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './QuestionnaireEvaluationOverview.scoped.scss';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { selectPatientById } from '../../redux/patientsSlice';
import helpers from '../../helpers/staticHelperMethods';
import { calculateQuestionnaireScore } from '../../helpers/calculateQuestionnaireScore';
import TitleService from '../../services/title/titleService';
import IceScore from '../common/ice-score/IceScore';

function QuestionnaireEvaluationOverview(props) {
  const { t } = props;
  const navigate = useNavigate();
  const { patientId: patientIdString } = useParams();

  const patientId = parseInt(patientIdString, 10);
  const currentPatient = {
    ...useSelector((state) => selectPatientById(state, patientId)),
  };

  useEffect(() => {
    if (currentPatient) {
      // change title
      // Add translations to distingush between Patient Details / Overview / Questionnare in Title
      TitleService.setTitle(`${currentPatient.study_number} - ${t('questionnaireOverview.ice_score')} - ${t('questionnaireOverview.overview')}`);
      window.scrollTo(0, 0);
    }
    // trigger event for header generation
    const patientDetailPageEvent = new CustomEvent('patientDetailPageEvent', { detail: currentPatient });
    document.dispatchEvent(patientDetailPageEvent);
  }, [currentPatient]);

  return (
    <div className="page-container">
      {
        currentPatient.questionnaires.length > 0 ?
          <div className="list-container">
            <ListGroup>
              {
                currentPatient.questionnaires.map(questionnaireEvaluation => {
                  const { date } = questionnaireEvaluation;
                  const score = calculateQuestionnaireScore(currentPatient, date);
                  const valueAndTimestamp = {
                    value: score,
                    timestamp: date,
                    updatedAt: date,
                  };
                  return (
                    <ListGroup.Item
                      action
                      key={date}
                      onClick={() => navigate(`./${date}`)}
                    >
                      <div>
                        <p>{helpers.getDateString(new Date(date), t, false, true)}</p>
                        <IceScore valueAndTimestamp={valueAndTimestamp}/>
                      </div>
                    </ListGroup.Item>
                  );
                })
              }
            </ListGroup>
          </div> :
          <p className="no-questionnaires-text">{t('questionnaireOverview.noQuestionnairesText')}</p>
      }
    </div>
  );
}

QuestionnaireEvaluationOverview.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(QuestionnaireEvaluationOverview);
