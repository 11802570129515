import React from 'react';
import { withTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PatientLastEntry from './patient-last-entry/PatientLastEntry';
import './PatientCard.scss';
import PatientCardHeader from './patient-card-header/PatientCardHeader';
import patientShape from '../../../../../prop-types/patient';
import CheckedIndicator from './checked-indicator/CheckedIndicator';
import SymptomGrid from './symptom-grid/SymptomGrid';
import valueLimitsShape from '../../../../../prop-types/valueLimits';
import { calculateQuestionnaireScore } from '../../../../../helpers/calculateQuestionnaireScore';
import e2eTestIds from '../../../../e2eTestIds';

/**
 * Card that displays all information about a patient. Can be minimized.
 * Then only the most important information about the patient is displayed in one line.
 * @param props
 */
const PatientCard = (props) => {
  const navigate = useNavigate();

  const {
    patient, valueLimits, t,
  } = props;

  const patientCardClassName = () => {
    const baseClassName = 'card col-12';
    if (!patient.isChecked) {
      return `${baseClassName} patient-card-blue-border-left`;
    }
    return `${baseClassName} patient-card-no-border-left`;
  };

  const patientLastEntry = (isMonitored) => {
    if (isMonitored) {
      return (
        <PatientLastEntry
          latestEntry={patient.latestEntry}
          checkedTimestamp={patient.checkedTimestamp}
          isChecked={patient.isChecked}
        />
      );
    }
    return (
      <span id="monitoring_disabled">
        {t('patientCard.monitoring_disabled')}
      </span>
    );
  };

  const attentionNeeded = patient.questionnaires.some(questionnaireEvaluation =>
    calculateQuestionnaireScore(patient, questionnaireEvaluation.date) === null);

  return (
    <div
      className={patientCardClassName()}
      data-testid={e2eTestIds.dashboard.patientCard.container(patient.surname, patient.name)}
    >
      <div className="card-body no-padding-right">
        <div className="row bg-white no-padding-right no-margin-right">
          <div className="col-xl-11 border-right p-0">
            <div className="col-xl no-padding-left">
              {patientLastEntry(patient.monitored)}
            </div>
            <div className="col-xl">
              <PatientCardHeader patient={patient}/>
            </div>
            <Collapsible open={!patient.isChecked} trigger="">
              <div className="col-xl-12">
                <div className="container-lg">
                  <div className="row flex-style symptomgrid">
                    <SymptomGrid
                      symptoms={patient.symptoms}
                      valueLimits={valueLimits}
                      treatment={patient.treatment}
                    />
                  </div>
                </div>
              </div>
            </Collapsible>
          </div>
          <div
            className="col p-0 align-top text-center d-flex flex-column justify-content-between right-side-icon-column"
          >
            <CheckedIndicator
              className="checked-indicator"
              patientId={patient.id}
              isChecked={patient.isChecked}
            />
            {!patient.isChecked && patient.treatment === 'CAR_T' && <FontAwesomeIcon
              size="2x"
              icon={faListCheck}
              // eslint-disable-next-line no-constant-condition
              className={`ice-icon clickable ${attentionNeeded ? 'attention-needed' : 'no-attention-needed'}`}
              onClick={() => navigate(`patient/${patient.id}/questionnaire`)}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('common')(PatientCard);

PatientCard.propTypes = {
  patient: patientShape.isRequired,
  valueLimits: valueLimitsShape.isRequired,
  t: PropTypes.func.isRequired,
};
